import './style/sellerProfile.scss';
import { ImLocation } from 'react-icons/im';
import { BsFillTelephoneFill } from 'react-icons/bs';
import {
  MdEmail,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { GetRequest } from '../../../components/Request';
import About from './About';
import { FiSearch } from 'react-icons/fi';
import { FaCartArrowDown, FaRegImage } from 'react-icons/fa';
import { CiShare2 } from 'react-icons/ci';
import { RxCross2 } from 'react-icons/rx';
import { WhatsappIcon, WhatsappShareButton } from 'react-share';
import copy from 'clipboard-copy';
import { debounce } from '../../../utils/util';
import { useSelector } from 'react-redux';
import { profileTypeSelector } from '../../../features/auth/Slice';
import { DomainUrl } from './enum/enum';
import { ProfileTypeEnum } from '../../../enum/profileType.enum';
import { BASE_URL } from '../../../constant/constant';

export default function SellerProfile() {
  let navigate = useNavigate();
  const profile = useSelector(profileTypeSelector);
  const inputRef = useRef<any>(null);
  const [isCopied, setIsCopied] = useState(false);
  const title = 'Check out my store to buy products!';
  const { slug } = useParams();
  const [brandList, setBrandList] = useState<any>([]);
  const brandListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreBrandListOrNot, setHasMoreBrandListOrNot] = useState(false);
  const [pageNumberForBrand, setPageNumberForBrand] = useState(1);
  const [sellerSlug, setSellerSlug] = useState<any>(slug);
  const [selectedNavigation, setSelectedNavigation] = useState('home');
  const [storeInfo, setStoreInfo] = useState<any>({});
  const [searchedValue, setSearchedValue] = useState<any>('');
  const [pageNumber, setPageNumber] = useState(1);
  const storeListRef = useRef<any>(null);
  const storeListAndSearchBarRef = useRef<any>(null);
  const [allStore, setAllStore] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const storeListRefForInfiniteScrolling = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const sharePopupRef = useRef<any>(null);

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const handleClickOutsideForSharePopUp = (event: any) => {
    if (
      sharePopupRef.current &&
      !sharePopupRef.current.contains(event.target)
    ) {
      setPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForSharePopUp);
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideForSharePopUp
      );
    };
  }, []);

  const handleCopy = () => {
    // Select the text in the input field
    inputRef.current.select();

    // Copy the selected text to the clipboard
    copy(inputRef.current.value);

    // Update the state to indicate that the link has been copied
    setIsCopied(true);

    // Reset the label to "Copy Link" after 2 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const onIntersection = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMore) {
      await setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (storeListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersection);
      observer.observe(storeListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allStore.length > 0, hasMore]);

  const handleClickOutside = (event: any) => {
    if (
      storeListAndSearchBarRef.current &&
      !storeListAndSearchBarRef.current.contains(event.target)
    ) {
      setAllStore([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getStoreDetails();
  }, [sellerSlug]);

  useEffect(() => {
    getBuyerAndSeller();
  }, [pageNumber]);

  const getStoreDetails = async () => {
    await GetRequest(`store/${sellerSlug}?profile-detail=true`)
      .then((res) => {
        if (res.data.statusCode == 200) {
          setPageNumberForBrand(1);
          setPageNumber(1);
          if (!res.data.data.profileDetail) {
            setStoreInfo(res.data.data);
          } else {
            if (res.data.data.profileDetail.banner.imageList.length > 0) {
              setStoreInfo({
                ...res.data.data,
                activeBannerIndex: 0,
              });
            } else {
              setStoreInfo(res.data.data);
            }
          }
        }
      })
      .catch((err) => console.log(err, 'err in getting the info'));
  };

  const manageRedirection = (storeInfo: any) => {
    if (profile === ProfileTypeEnum.User) {
      return BASE_URL + `product`;
    } else if (profile === ProfileTypeEnum.Store) {
      return (
        BASE_URL +
        `product?store_name=${storeInfo.name}&store_id=${storeInfo._id}&only_store_product=true`
      );
    } else {
      return BASE_URL + `login`;
    }
  };

  const handleClickToChangeBanner = (bannerIndex: any) => {
    if (bannerIndex < storeInfo.profileDetail.banner.imageList.length - 1) {
      let updatedStoreInfo = {
        ...storeInfo,
      };
      updatedStoreInfo.activeBannerIndex = bannerIndex + 1;
      setStoreInfo(updatedStoreInfo);
    }

    if (bannerIndex > 0) {
      let updatedStoreInfo = { ...storeInfo };
      updatedStoreInfo.activeBannerIndex = bannerIndex - 1;
      setStoreInfo(updatedStoreInfo);
    }
  };

  const handleChangeOnSearchedValue = async (event: any) => {
    if (event.target.value.length === 0) {
      setAllStore([]);
    }
    await setSearchedValue(() => event.target.value);
    await setPageNumber(() => 1);
  };

  const getBuyerAndSeller = async () => {
    if (searchedValue) {
      await GetRequest(
        `store?name=${searchedValue}&limit=8&page=${pageNumber}`
      ).then(async (res) => {
        if (res.data.statusCode === 200) {
          setAllStore((prevState: any) => {
            return pageNumber > 1
              ? prevState.concat(res.data.data)
              : res.data.data;
          });
          if (res.data.data.length < 8) {
            await setHasMore(() => false);
          } else {
            await setHasMore(() => true);
          }
        }
      });
    }
  };

  const handleClickOnStore = (data: any) => {
    setAllStore([]);
    setPageNumber(1);
    setSearchedValue('');
    setSellerSlug(data.slug);
    navigate(`/store/${data.slug}`);
  };

  // use this infinite scrolling starts everywhere

  const onIntersectionForBrandList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreBrandListOrNot) {
      setPageNumberForBrand((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (brandListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForBrandList);
      observer.observe(brandListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [brandList.length > 0, hasMoreBrandListOrNot]);

  useEffect(() => {
    if (storeInfo._id) {
      getRegistreredBrand();
    }
  }, [pageNumberForBrand, storeInfo]);

  const getRegistreredBrand = async (newStore: boolean = false) => {
    await GetRequest(
      `seller/store/${storeInfo._id}/brand?seller-brand-product-total-count=true&page=${pageNumberForBrand}&limit=8`
    ).then(async (res) => {
      if (res.data.statusCode === 200) {
        await setBrandList((prevState: any) =>
          pageNumberForBrand > 1
            ? prevState.concat(res.data.data)
            : res.data.data
        );

        if (res.data.data.length < 8) {
          await setHasMoreBrandListOrNot(false);
        } else {
          await setHasMoreBrandListOrNot(true);
        }
      }
    });
  };

  // ends here

  return (
    <div className="profile">
      <div className="search-bar-component">
        <div className="search-bar" ref={storeListAndSearchBarRef}>
          <input
            type="text"
            placeholder="Search Store..."
            value={searchedValue}
            onChange={handleChangeOnSearchedValue}
            onKeyUp={() => debounce(getBuyerAndSeller, 500)}
          />
          <button className="search-icon">
            <FiSearch />
          </button>
          {allStore.length > 0 ? (
            <ul
              className="store-list"
              style={{ overflowY: 'scroll' }}
              ref={storeListRef}
            >
              {allStore.map((data: any, index: number) => (
                <li
                  key={data._id}
                  onClick={() => handleClickOnStore(data)}
                  className="each-store"
                >
                  <img
                    src={
                      data?.profileImage
                        ? data?.profileImage
                        : '/distributor-icon.svg'
                    }
                    alt={data._id}
                    className="store-img"
                  />
                  <div>
                    <p className="name"> {data.name}</p>
                    <p className="location">
                      {data.address.district},{data.address.state},
                      {data.address.country} ,{data.address.postalCode}
                    </p>
                  </div>
                </li>
              ))}
              {hasMore && allStore.length > 0 && (
                <div
                  style={{
                    display: 'block',
                    height: '50px',
                  }}
                  ref={storeListRefForInfiniteScrolling}
                ></div>
              )}
            </ul>
          ) : (
            ''
          )}
        </div>
      </div>

      {popupVisible && (
        <div className="share-popup" ref={sharePopupRef}>
          <button className="close-button" onClick={hidePopup}>
            <RxCross2 />
          </button>

          <div className="share-buttons">
            <div className="share-icon-cnt">
              <input
                style={{
                  border: '1px solid #ddd',
                  padding: '8px',
                  borderRadius: '24px',
                }}
                className="copy-input-link"
                type="text"
                value={
                  DomainUrl.ModulusBuyProduction + `store/${storeInfo.slug}`
                }
                ref={inputRef}
                readOnly
              />
              <div className="social-media-icon copy-link-cnt">
                <button onClick={handleCopy}>
                  {isCopied ? 'Copied!' : 'Copy Link'}
                </button>
              </div>{' '}
              <div className="social-media-icon">
                <WhatsappShareButton
                  url={
                    DomainUrl.ModulusBuyProduction + `store/${storeInfo.slug}`
                  }
                  title={title}
                  separator=":: "
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>
      )}

      {Object.keys(storeInfo).length > 0 && (
        <>
          <div className="cover-image">
            {storeInfo.profileDetail &&
            storeInfo.profileDetail?.coverImage &&
            storeInfo.profileDetail?.coverImage?.image ? (
              <img src={storeInfo.profileDetail.coverImage.image} alt="Cover" />
            ) : (
              <div className="cover-picture-cnt">
                <FaRegImage className="img-icon" />
                <p
                  className="upload-text"
                  // onClick={() => {

                  // navigate('/profile#store-public-profile')}}
                >
                  No cover picture found
                </p>
              </div>
            )}
          </div>
          <div className="profile-details">
            <div className="profile-image-n-seller-name">
              <img
                src={
                  storeInfo?.profileImage
                    ? storeInfo?.profileImage
                    : '/distributor-icon.svg'
                }
                className="seller-img"
                alt={storeInfo.name}
              />
              <div className="info-like-address-n-contact">
                <div>
                  <h1 className="seller-name">{storeInfo.name}</h1>
                  <p className="location">
                    <ImLocation className="icon" /> {storeInfo.address.district}
                    ,{storeInfo.address.state}, {storeInfo.address.country} ,
                    {storeInfo.address.postalCode}
                  </p>
                </div>
                <div>
                  {storeInfo.phoneNumberList?.[0]?.nationalNumber ? (
                    <p className="phone-number">
                      <BsFillTelephoneFill className="icon" />
                      {storeInfo.phoneNumberList[0]?.nationalNumber}
                    </p>
                  ) : (
                    ''
                  )}

                  {storeInfo.phoneNumberList?.[0]?.phoneNumber ? (
                    <p className="phone-number">
                      <BsFillTelephoneFill className="icon" />
                      {storeInfo.phoneNumberList[0]?.phoneNumber}
                    </p>
                  ) : (
                    ''
                  )}

                  <p className="email">
                    <MdEmail className="icon" /> {storeInfo.email}
                  </p>
                </div>
              </div>
            </div>

            <div className="banner-n-arrow-cnt">
              {storeInfo.profileDetail &&
                storeInfo.profileDetail.banner.imageList?.length > 1 && (
                  <button
                    style={{
                      cursor: 'pointer',
                      background: '#a6c5e978',
                    }}
                    onClick={() =>
                      handleClickToChangeBanner(storeInfo.activeBannerIndex)
                    }
                    className="arrows left_arrow"
                  >
                    <MdKeyboardArrowLeft />
                  </button>
                )}

              {storeInfo.profileDetail &&
                storeInfo.profileDetail.banner.imageList?.length > 1 && (
                  <img
                    src={
                      storeInfo.profileDetail.banner.imageList[
                        storeInfo.activeBannerIndex
                      ].image
                    }
                    className="banner-img"
                    alt={'banner'}
                  />
                )}

              {storeInfo.profileDetail &&
                storeInfo.profileDetail.banner.imageList?.length > 1 && (
                  <button
                    className="arrows right_arrow"
                    style={{
                      cursor: 'pointer',
                      background: '#a6c5e978',
                    }}
                    onClick={() =>
                      handleClickToChangeBanner(storeInfo.activeBannerIndex)
                    }
                  >
                    <MdKeyboardArrowRight />
                  </button>
                )}
            </div>

            <div>
              <nav className="home-n-about-us">
                <a
                  className={
                    selectedNavigation == 'home' ? 'link active-link' : 'link'
                  }
                  onClick={() => setSelectedNavigation('home')}
                >
                  Home
                </a>
                <a
                  className={
                    selectedNavigation == 'about' ? 'link active-link' : 'link'
                  }
                  onClick={() => setSelectedNavigation('about')}
                >
                  About us
                </a>
              </nav>
            </div>
          </div>
          <div className="special-btn-cnt"></div>

          {selectedNavigation != 'home' ? (
            <About storeInfo={storeInfo} />
          ) : (
            <div className="seller-info-n-product-cnt">
              <div
                className="seller-info-section"
                style={{
                  flexBasis: storeInfo.seller ? '30%' : '100%',
                }}
              >
                <div className="about-seller">
                  <h3 className="tag">About us</h3>
                  <p className="description">
                    {storeInfo.profileDetail &&
                      storeInfo.profileDetail.aboutUs.text}
                  </p>
                </div>
                <div className="store-photos">
                  <h3 className="tag">Photos</h3>
                  <div className="store-photos-cnt">
                    {storeInfo.imageList?.length > 0
                      ? storeInfo.imageList.map((data: any, index: number) => {
                          return (
                            <img
                              src={data}
                              className="store-img"
                              alt={data + '-' + index}
                            />
                          );
                        })
                      : ''}
                  </div>
                </div>
                <div className="store-on-map">
                  <h3 className="tag">See on map</h3>
                  <iframe
                    src={`https://maps.google.com/maps?q=${storeInfo.location.coordinates[1]}, ${storeInfo.location.coordinates[0]}&z=15&output=embed`}
                    frameBorder="0"
                    style={{
                      border: 0,
                      width: '100%',
                      height: 'auto',
                      borderRadius: '10px',
                    }}
                  ></iframe>
                </div>
              </div>
              {storeInfo.seller && (
                <div className="product-section">
                  <h2 className="heading-of-product-section">
                    Brands Selling By {storeInfo.name}
                  </h2>

                  <ul className="brand-list">
                    {brandList.length > 0 &&
                      brandList?.map((data: any) => {
                        return (
                          <li className="each-brand" key={data._id}>
                            <div className="brand-logo-cnt">
                              <img
                                src={
                                  data.brand?.logo
                                    ? data.brand?.logo
                                    : '/images/no-image-available-icon.jpeg'
                                }
                                style={{
                                  border: data.brand?.image
                                    ? '1px solid #ddd'
                                    : 'none ',
                                }}
                                className="brand-logo-img"
                                alt="brand-logo"
                              />
                            </div>
                            <h2
                              className="brand-name"
                              style={{
                                marginTop: !data.brand?.image
                                  ? '0px'
                                  : '0.5rem',
                              }}
                            >
                              {data.brand.name}
                            </h2>
                            <p className="total-product-count">
                              {data.sellerBrandProductTotalCount} Total Products
                            </p>
                          </li>
                        );
                      })}
                    {brandList.length > 0 && (
                      <div
                        ref={brandListRefForInfiniteScrolling}
                        style={{ padding: '20px' }}
                      ></div>
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}

          <footer className="footer-nav">
            <nav className="sharing-nav-link-cnt">
              <ul>
                {storeInfo.seller ? (
                  <li className="tooltip">
                    <Link to={manageRedirection(storeInfo)} className="link">
                      <FaCartArrowDown />
                      <div className="tooltiptext">Buy Products</div>
                    </Link>
                  </li>
                ) : (
                  ''
                )}
                <li className="tooltip" onClick={showPopup}>
                  <p className="link">
                    <CiShare2
                      style={{
                        color: 'white',
                        fontSize: '1.5rem',
                      }}
                    />
                    <div className="tooltiptext">Share</div>
                  </p>
                </li>
              </ul>
            </nav>
          </footer>
        </>
      )}
    </div>
  );
}
