export function generateDynamicLink(storeSlug: string): string {
  let searchPrams = new URLSearchParams({
    link: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    apn: "com.modulusbuy.android",
    amv: "27",
    afl: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    ofl: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    ibi: `com.modulusbuy.ios`,
    ifl: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    imv: "27",
    utm_campaign: "banner"
  });
  return "https://modulusbuymobile.page.link/?" + searchPrams;
}


let timeoutId: any;
export const debounce = function (callback: Function, delay: number=500) {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    callback();
    timeoutId = null;
  }, delay);
};
