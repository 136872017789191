// for store profile
import { RxCross2 } from 'react-icons/rx';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  GetRequest,
  PatchRequest,
  PostRequest,
} from '../../../../components/Request';
import { useState, useEffect } from 'react';
import { BsFillCameraFill } from 'react-icons/bs';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store';
import {
  IUserStore,
  UpdateSellerStoreAsyncThunk,
  storeProfileSelector,
} from '../../../../features/auth/Slice';
import { IStore } from '../../../../features/buyerStore/Slice';
import InputText from '../../../../common/InputText/InputText';
import { StoreCategoryEnum } from '../../addStore/storeCategoryEnum/storeCategory.enum';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function StoreProfile(props: any) {
  let {
    setShowIndicator,
    setMessage,
    setType,
    shopInfo,
    setShopInfo,
    setSelectedNavigation,
    handleChange,
    validateEmail,
  } = props;
  const navigate = useNavigate();

  const [shopTypes, setShopTypes] = useState<any>([]);

  const getStoreTypeList = async () => {
    await GetRequest('/store/type').then((res) => {
      if (res.data.statusCode === 200) {
        setShopTypes(() => {
          return res.data.data.map((data: any) => data.value);
        });
      }
    });
  };

  useEffect(() => {
    getStoreTypeList();
  }, []);

  const categoryList = [
    StoreCategoryEnum.Retailer,
    StoreCategoryEnum.Manufacturer,
    StoreCategoryEnum.Distributor,
    StoreCategoryEnum.Wholesaler,
  ];

  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  let { currentStep, setCurrentStep } = props;

  const [panCardInfo, setPanCard] = useState<any>({
    pancard: '',
    isPanCardValid: null,
  });

  function handleDragStart(event: any) {
    event.dataTransfer.setData('text/plain', event.currentTarget.dataset.index);
  }

  function handleDragOver(event: any) {
    event.preventDefault();
  }

  function handleDrop(event: React.DragEvent<HTMLElement>) {
    event.preventDefault();

    const oldIndex = Number(event.dataTransfer.getData('text/plain'));
    const newIndex = Number(event.currentTarget.dataset.index);
    const maxIndex = shopInfo.shopImages.length - 1;

    if (
      oldIndex < 0 ||
      oldIndex > maxIndex ||
      newIndex < 0 ||
      newIndex > maxIndex
    ) {
      return; // Invalid index, do not perform the replacement
    }

    if (oldIndex === newIndex) {
      return; // No need to perform the replacement if oldIndex and newIndex are the same
    }

    setStoreData((pre: any) => {
      const newShopImages = [...pre.imageList];
      const draggedImage = newShopImages[oldIndex];
      newShopImages.splice(oldIndex, 1); // Remove the dragged element
      newShopImages.splice(newIndex, 0, draggedImage); // Insert the dragged element at the drop position

      return {
        ...pre,
        imageList: newShopImages,
      };
    });
  }

  const updateShopInfo = async () => {
    await PatchRequest('store', {
      profileImage: shopInfo.profileImage,
      name: shopInfo.shopName,
      category: 'Distributor',
      typeList: shopInfo.shopType,
      imageList: shopInfo.shopImages,
    })
      .then((res: any) => {
        if (res.status === 200) {
          setShowIndicator(true);
          setMessage('Updated');
          setType('success');
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setShowIndicator(true);
        setMessage(err.response.data.message);
        setType('error');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      });
  };

  async function handleFileInputChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.files?.length) {
      try {
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        const response = await PostRequest('file?type=store-image', formData);

        setStoreData((pre: any) => {
          return {
            ...pre,
            imageList: pre?.imageList
              ? pre?.imageList.concat(response.data.data.url)
              : pre?.images.concat(response.data.data.url),
          };
        });
      } catch (error) {}
    } else {
    }
  }

  const handleUpdateShopProfile = () => {
    updateShopInfo();
  };

  const dispatch = useDispatch<AppDispatch>();
  const [storeData, setStoreData] = useState<any>({});
  const store = useSelector(storeProfileSelector);
  useEffect(() => {
    if (store) {
      setStoreData((pre: any) => store);
    }
  }, []);
  async function handleProfileChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.files?.length) {
      try {
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        const response = await PostRequest('file?type=store-image', formData);
        await dispatch(
          UpdateSellerStoreAsyncThunk({ profileImage: response.data.data.url })
        );
      } catch (error) {}
    } else {
    }
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setStoreData((pre: any) => ({
      ...pre,
      [event.target.name]: event.target.value,
    }));

    if (event.target.name === 'name') {
      setShopInfo((prevState: any) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            name: event.target.value.length === 0 ? 'Name is required' : '',
          },
        };
      });
    }
  }
  function traverseObject(obj: any): void {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object') {
          traverseObject(obj[key]);
        } else {
          console.log(key, obj[key]);
        }
      }
    }
  }

  async function handleUpdateProfile() {
    traverseObject(storeData);

    let { typeList, categoryList, name, profileImage, imageList } =
      shopInfo.errors;

    if (!typeList && !categoryList && !name && !profileImage && !imageList) {
      let { categoryList, typeList, profileImage, name, imageList } = storeData;
      let response = await dispatch(
        UpdateSellerStoreAsyncThunk({
          categoryList,
          typeList,
          profileImage,
          name,
          imageList,
        })
      );

      if (response.meta.requestStatus === 'fulfilled') {
        setShowIndicator(true);
        setMessage('Updated Successfully');
        setType('success');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      }
    }
  }

  useEffect(() => {
    setStoreData(() => store);
  }, [store]);

  return (
    <div className="cnt_of_stepone store-profile-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => {
            setSelectedNavigation('');
            navigate('/profile');
          }}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <form action="#">
        <div className="flex py-5 justify-center relative">
          {store?.profileImage ? (
            <img
              src={store?.profileImage}
              className="w-[255px] h-[137px]  object-contain "
              style={{
                borderRadius: '4rem',
                border: '1px solid #ddd',
              }}
              alt={shopInfo.name}
            />
          ) : (
            <div
              style={{
                borderRadius: '4rem',
                border: '1px solid #ddd',
              }}
              className="w-[255px] h-[137px]  flex justify-center items-center"
            >
              <img
                style={{
                  width: '100px',
                  color: '#14539A',
                  height: '100px',
                }}
                src="/distributor-icon.svg"
                alt="distributor-icon"
                className="user_icon"
              />
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '8%',
              background: '#14539a',
              width: '45px',
              height: '45px',
              lineHeight: '40px',
              textAlign: 'center',
              borderRadius: '50%',
              overflow: 'hidden',
              border: '4px solid white',
              cursor: 'pointer',
            }}
            className="flex cursor-pointer right-[40%] md:right-[36%] justify-center items-center"
          >
            <input
              type="file"
              max="1000"
              style={{
                position: 'absolute',
                transform: 'scale(2)',
                opacity: '0',
                cursor: 'pointer',
              }}
              accept=".jpg, .jpeg, .png"
              name="profileImage"
              onChange={handleProfileChange}
            />
            <BsFillCameraFill
              style={{
                cursor: 'pointer',
              }}
              className="w-[28px] h-[28px] text-white"
            />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <span className="error"> {shopInfo.errors.profileImage}</span>
        </div>
        <div className="flex my-10 justify-between sm:flex-col ">
          <div className="basis-[45%] sm:mb-10">
            <InputText
              name="name"
              value={storeData?.name}
              label="Store Name *"
              initialValue={store?.name}
              handleChange={handleInputChange}
              placeholder="Enter you shop name"
            />
            {shopInfo.errors.name && (
              <span className="error">{shopInfo.errors.name}</span>
            )}
          </div>

          <div className="basis-[45%] relative">
            <Stack spacing={3}>
              <Autocomplete
                multiple
                id="tags-outlined"
                value={storeData?.categoryList ?? store?.categoryList}
                options={categoryList}
                onChange={(event, newValue) => {
                  setStoreData((prevState: any) => ({
                    ...prevState,
                    categoryList: [...newValue],
                    errors: {
                      ...prevState.errors,
                      categoryList: '',
                    },
                  }));

                  setShopInfo((prevState: any) => ({
                    ...prevState,
                    categoryList: [...newValue],
                    errors: {
                      ...prevState.errors,
                      categoryList: '',
                    },
                  }));

                  if (newValue.length === 0) {
                    setShopInfo((prevState: any) => {
                      let { errors } = prevState;
                      return {
                        ...prevState,
                        categoryList: newValue,
                        errors: {
                          ...prevState.errors,
                          categoryList: 'Add at least one shop category',
                        },
                      };
                    });
                  }

                  // if (newValue.length !== 0) {
                  //   setStoreData((pre: any) => ({
                  //     ...pre,
                  //     categoryList: [...newValue],
                  //   }));
                  //   setShopInfo((prevState: any) => ({
                  //     ...prevState,

                  //     errors: {
                  //       ...prevState.errors,
                  //       categoryList: '',
                  //     },
                  //   }));
                  // } else {
                  //   setShopInfo((prevState: any) => {
                  //     let { errors } = prevState;
                  //     return {
                  //       ...prevState,
                  //       categoryList: newValue,
                  //       errors: {
                  //         ...prevState.errors,
                  //         categoryList: 'Add at least one shop type',
                  //       },
                  //     };
                  //   });
                  // }
                }}
                getOptionLabel={(shopType: any) => shopType}
                filterSelectedOptions
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    sx={{
                      '& .css-1qqsdnr-MuiAutocomplete-root': {
                        padding: '4px',
                      },
                      '& .MuiOutlinedInput-root': {
                        padding: '4px',
                      },
                    }}
                    name="categoryList"
                    label="Store Category"
                    placeholder="for eg: grocery , gifts and etc."
                    error={shopInfo.errors.categoryList ? true : false}
                    helperText={
                      shopInfo.errors.categoryList
                        ? shopInfo.errors.categoryList
                        : ''
                    }
                    required
                  />
                )}
              />
            </Stack>
          </div>
        </div>

        <div className="flex justify-between sm:flex-col ">
          <div className="basis-[45%]">
            <Stack spacing={3}>
              <Autocomplete
                multiple
                id="tags-outlined"
                value={storeData?.typeList ?? store?.typeList}
                options={shopTypes}
                onChange={(event, newValue) => {
                  setStoreData((prevState: any) => ({
                    ...prevState,
                    typeList: [...newValue],
                    errors: {
                      ...prevState.errors,
                      typeList: '',
                    },
                  }));

                  setShopInfo((prevState: any) => ({
                    ...prevState,
                    typeList: [...newValue],
                    errors: {
                      ...prevState.errors,
                      typeList: '',
                    },
                  }));

                  if (newValue.length === 0) {
                    setShopInfo((prevState: any) => {
                      let { errors } = prevState;
                      return {
                        ...prevState,
                        typeList: newValue,
                        errors: {
                          ...prevState.errors,
                          typeList: 'Add at least one shop type',
                        },
                      };
                    });
                  }
                }}
                getOptionLabel={(shopType: any) => shopType}
                filterSelectedOptions
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    sx={{
                      '& .css-1qqsdnr-MuiAutocomplete-root': {
                        padding: '4px',
                      },
                      '& .MuiOutlinedInput-root': {
                        padding: '4px',
                      },
                    }}
                    name="typeList"
                    label="Store Type"
                    placeholder="for eg: grocery , gifts and etc."
                    error={shopInfo.errors.typeList ? true : false}
                    helperText={
                      shopInfo.errors.typeList ? shopInfo.errors.typeList : ''
                    }
                    required
                  />
                )}
              />
            </Stack>
          </div>
          <div className="basis-[45%]"></div>
        </div>

        <div className="mb-4 mt-[1.5rem]">
          <div className="mb-[-0.7rem] ml-[0.5rem]">
            <label
              htmlFor="shopImage"
              className="text-sm bg-white px-[0.2rem] lg:text-xs font-semibold"
            >
              Shop's Image
            </label>
          </div>

          {/* card for showing uploaded images by the user */}
          <div
            className="basis-[90%] sm:p-[0.5rem] flex flex-wrap rounded border-solid border-[1px] p-4 border-gray-220"
            style={{
              borderColor: '#ddd',
            }}
          >
            {storeData &&
              storeData?.imageList &&
              storeData?.imageList?.length > 0 &&
              storeData?.imageList?.map((img: string, index: number) => (
                <figure
                  key={index}
                  draggable
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  data-index={index}
                  className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                  style={{
                    width: '110px',
                    height: '110px',
                  }}
                >
                  <span
                    onClick={() =>
                      setStoreData((pre: any) => ({
                        ...pre,
                        imageList: pre.imageList.filter(
                          (value: any, ind: number) => ind !== index
                        ),
                      }))
                    }
                    style={{
                      position: 'absolute',
                      right: '4px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: 'white',
                      top: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <RxCross2 />
                  </span>
                  <img
                    src={img}
                    className="rounded-md w-full h-[110px] object-cover"
                    alt={'shop-image' + index}
                  />
                </figure>
              ))}
            <button
              className="w-[110px] m-[0.4rem] sm:m-[0.2rem] p-[24px] h-[110px] border-box bg-[#EAECEC] h-auto flex-col flex items-center justify-center border-solid border-[1px] border-gray-220 rounded-md"
              style={{
                width: '110px',
                height: '110px',
              }}
            >
              <i
                style={{
                  backgroundImage: 'url("/add_photos_icon.png")',
                  backgroundPosition: '0px -58px',
                  backgroundSize: 'auto',
                  color: 'white',
                  width: '20px',
                  height: '20px',
                  backgroundRepeat: 'no-repeat',
                  display: 'inline-block',
                }}
              ></i>
              <span className="text-xs mt-2">Add Photos</span>
              <input
                type="file"
                max="1000"
                name="imageList"
                style={{
                  position: 'absolute',
                  opacity: '0',
                  cursor: 'pointer',
                  width: '110px',
                  top: '100',
                  height: '110px',
                }}
                accept=".jpg, .jpeg, .png"
                onChange={handleFileInputChange}
              />
            </button>
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            <span className="error"> {shopInfo.errors.imageList}</span>
          </div>
        </div>

        <hr />
        <div className="text-right my-10 mx-2 store-profile-update-cnt">
          <button
            type="button"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleUpdateProfile}
            className={'button-primary md:w-[5rem] md:text-[12px]'}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
