import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
export type ICategory = {
  _id: string;
  status: string;
  updatedBy: string;
  createdBy: string;
  name: string;
  description: string;
  type: string;
  icon: string;
  createdAt: Date;
  updatedAt: Date;
  subCategory: SubCategory[];
};

export type SubCategory = {
  name: string;
  description: string;
  icon: string;
  _id: string;
};

interface IProductCategorySlice {
  categoryList: ICategory[];
}

const initialState: IProductCategorySlice = {
  categoryList: [],
};

export const productCategorySlice = createSlice({
  name: 'categoryList',
  initialState,
  reducers: {
    addCategoryList: (state, { payload }: PayloadAction<ICategory[]>) => {
      state.categoryList = payload;
    },
  },
});

export const { addCategoryList } = productCategorySlice.actions;
export default productCategorySlice.reducer;

export const productCategorySelector = (state: RootState) =>
  state.categoryList.categoryList;
