import React from "react";
import "./styles/invoice.css";
import { IOrder } from "../../interface/axios.types";
import { LazyLoadImage } from "react-lazy-load-image-component";
type Props = {
  order: IOrder;
  handlePrint: () => void;
};
export const ComponentToPrint = React.forwardRef(
  ({ order, handlePrint }: Props, ref) => {
    return (
      //@ts-ignore
      <div ref={ref} className="tm_container" style={{ zIndex: 1 }}>
        <div className="tm_invoice_wrap">
          <div
            className="tm_invoice tm_style1 tm_type1"
            id="tm_download_section"
          >
            <div className="tm_invoice_in">
              <div className="tm_invoice_head tm_top_head tm_mb15 tm_align_center">
                <div className="tm_invoice_left">
                  <div className="tm_logo">
                    <h3>{order.sellerStore.name}</h3>
                  </div>
                </div>
                <div className="tm_invoice_right tm_text_right tm_mobile_hide">
                  <div className="tm_f50 tm_text_uppercase tm_white_color">
                    Invoice
                  </div>
                </div>
                <div className="tm_shape_bg tm_accent_bg tm_mobile_hide"></div>
              </div>
              <div className="tm_invoice_info tm_mb25">
                <div className="tm_card_note tm_mobile_hide">
                  <b className="tm_primary_color">Payment Method: </b>
                  {order.payment.paymentMethod}
                </div>
                <div className="tm_invoice_info_list tm_white_color">
                  <p className="tm_invoice_number tm_m0">
                    Invoice No: <b>#{order._id.slice(-10)}</b>
                  </p>
                  <p className="tm_invoice_date tm_m0">
                    Date:{" "}
                    <b>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(order.createdAt))}
                    </b>
                  </p>
                </div>
                <div className="tm_invoice_seperator tm_accent_bg" />
              </div>
              <div className="tm_invoice_head tm_mb10">
                <div className="tm_invoice_left">
                  <p className="tm_mb2">
                    <b className="tm_primary_color">Invoice To:</b>
                  </p>
                  <p>
                    {order.buyerType == "User" ? (
                      <>
                        {order?.buyerUserInfo?.name ?? "Unregister"} <br />
                      </>
                    ) : (
                      <>
                        {order?.buyerStore?.name ?? "Unregister"} <br />
                        {order?.buyerStore?.address?.line1} <br />
                        {order?.buyerStore?.address?.district} <br />
                        {order?.buyerStore?.address?.state} <br />
                        {order?.buyerStore?.address?.postalCode}
                      </>
                    )}
                  </p>
                </div>
                <div className="tm_invoice_right tm_text_right">
                  <p className="tm_mb2">
                    <b className="tm_primary_color">Pay To:</b>
                  </p>
                  <p>
                    {order?.sellerStore?.name ?? "Unregister"} <br />
                    {order?.sellerStore?.address?.line1 && (
                      <>
                        {order?.sellerStore?.address?.line1} <br />
                      </>
                    )}
                    {order?.sellerStore?.address?.district} <br />
                    {order?.sellerStore?.address?.state},{" "}
                    {order?.sellerStore?.address?.postalCode}
                  </p>
                </div>
              </div>
              <div className="tm_table tm_style1">
                <div className="">
                  <div className="tm_table_responsive">
                    {/* <div className="mobile_visible">
                      <ul className="lists_of_distributors">
                        <>
                          <li
                            style={{ borderBottom: "1px solid lightgray" }}
                            className="each_distributor"
                          >
                            <div className="distributor_info">
                              <div className="cnt-of-seller">
                                <div className="user_icon">
                                  <img
                                    className="seller-profile-img"
                                    src={
                                      order.sellerStore.profileImage
                                        ? order.sellerStore.profileImage
                                        : "/images/distributor_icon.jpg"
                                    }
                                    alt="distributor"
                                  />
                                </div>
                                <div
                                  style={{
                                    flexBasis: "87%",
                                  }}
                                  className="margin-left"
                                >
                                  <div className="cnt_of_date_n_name">
                                    <h4 className="distributor_name">
                                      {order.sellerStore.name}
                                    </h4>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                    className="margin-left-20"
                                  >
                                    <div className="cnt_of_order_btn_n_total_price">
                                      <p className="date">
                                        {" "}
                                        {new Intl.DateTimeFormat("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        }).format(new Date(order.createdAt))}
                                      </p>
                                      <p className="total_item">
                                        <span>{"Items : "} </span>
                                        {order.totalItem}
                                      </p>
                                    </div>
                                    <div className="cnt_of_tax_n_total_item">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {order.totalTaxAmountList.map(
                                          (tax: any) => (
                                            <p className="order_tax">
                                              {tax.type}
                                              <span>
                                                {" "}
                                                ₹ {tax.amount.toFixed(2)}
                                              </span>
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        position: "relative",
                                        flexBasis: "32%",
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                      className="total-price-n-place-order-btn-cnt"
                                    >
                                      <p className="total_price seller-total-price">
                                        <span>{"Total" + " "} </span>
                                        {"     ₹" + order.totalAmount}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <ul className="orders">
                                {order.itemList.length > 0 &&
                                  order.itemList.map(
                                    (data: any, indexOfItem: number) => {
                                      return (
                                        <li className="product_cnt">
                                          <div className="product">
                                            <div className="cnt_of_product_img_n_quantity">
                                              <LazyLoadImage
                                                alt={data.product.name}
                                                className="product_img"
                                                src={
                                                  data.product.imageList ===
                                                  undefined
                                                    ? "/images/no-image-available-icon.jpeg"
                                                    : data.product.imageList[0]
                                                }
                                              />
                                            </div>

                                            <div
                                              style={{
                                                flexBasis: "80%",
                                                flexWrap: "wrap",
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                              className="marginLeft"
                                            >
                                              <div
                                                className="basis-50-mb"
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div>
                                                  <h1 className="title">
                                                    {data.product.name}
                                                  </h1>
                                                  <p className="price">
                                                    Price : ₹
                                                    {
                                                      data.pricePerUnit
                                                        .totalAmount
                                                    }
                                                  </p>
                                                  <p className="mrp-sm-screen-mobile">
                                                    MRP ₹ {data.product.mrp}
                                                  </p>
                                                  <p
                                                    className="order_id"
                                                    style={{
                                                      color: "#14539A",
                                                    }}
                                                  >
                                                    Total Pieces :{" "}
                                                    {
                                                      data.unitWiseQuantity
                                                        .quantity
                                                    }
                                                  </p>
                                                </div>
                                              </div>

                                              <div>
                                                <p className="mrp">
                                                  MRP ₹ {data.product.mrp}
                                                </p>
                                                <div className="tax-cnt">
                                                  {data.totalTaxPercentagePrice.map(
                                                    (
                                                      tax: any,
                                                      index: number
                                                    ) => {
                                                      return (
                                                        <p className="order_date tax">
                                                          {tax.type}
                                                          <span>
                                                            {"  " +
                                                              tax.percentage +
                                                              "%  "}
                                                          </span>
                                                        </p>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>

                                              <div className="cnt_of_options_n_total_price">
                                                <p
                                                  className="price"
                                                  style={{
                                                    margin: "0",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  <span className="total_span">
                                                    Total
                                                  </span>{" "}
                                                  ₹{data.totalPrice}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                              </ul> */}
                    {/* </div>
                          </li>
                        </>
                      </ul>
                    </div> */}
                    <div className="mobile_visible">
                      {order.itemList.map((item) => (
                        <div className="product_container">
                          <div className="image_container">
                            <LazyLoadImage
                              alt={item.product.name}
                              className="img"
                              style={{
                                width: 50,
                                height: 50,
                                objectFit: "contain",
                              }}
                              src={
                                item.product.imageList === undefined
                                  ? "/images/no-image-available-icon.jpeg"
                                  : item.product.imageList[0]
                              }
                            />
                          </div>
                          <div
                            style={{
                              width: "70%",
                              justifyContent: "flex-start",
                            }}
                          >
                            <p className="name">{item.product.name}</p>
                            <div className="product_detail">
                              <p>
                                {new Intl.NumberFormat("en-US", {
                                  currency: "INR",
                                  style: "currency",
                                }).format(item.pricePerUnit.totalAmount)}
                                {" X "}
                                {item.unitWiseQuantity.quantity}{" "}
                                {item.unitWiseQuantity.productUnit}
                              </p>

                              {item.grossPrice > item.totalPrice ? (
                                <p>
                                  <span
                                    style={{
                                      textDecorationLine: "line-through",
                                    }}
                                  >
                                    {new Intl.NumberFormat("en-US", {
                                      currency: "INR",
                                      style: "currency",
                                    }).format(item.grossPrice)}
                                  </span>
                                  {item.totalPrice
                                    ? new Intl.NumberFormat("en-US", {
                                        currency: "INR",
                                        style: "currency",
                                      }).format(item.totalPrice)
                                    : "Free"}
                                </p>
                              ) : (
                                <p>
                                  {new Intl.NumberFormat("en-US", {
                                    currency: "INR",
                                    style: "currency",
                                  }).format(item.totalPrice)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <table className="table_hidden_in_mobile">
                      <thead>
                        <tr className="tm_accent_bg">
                          <th className="tm_width_3 tm_semi_bold tm_white_color">
                            Item
                          </th>
                          <th className="tm_width_2 tm_semi_bold tm_white_color">
                            Price
                          </th>
                          <th className="tm_width_1 tm_semi_bold tm_white_color">
                            Qty
                          </th>
                          <th className="tm_width_2 tm_semi_bold tm_white_color tm_text_right">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order?.itemList?.map((item, index) => (
                          <tr key={item._id}>
                            <td className="tm_width_3">
                              {index + 1}. {item?.product?.name}
                            </td>
                            <td className="tm_width_2">
                              {item.pricePerUnit.totalAmount}
                            </td>
                            <td className="tm_width_1">
                              {item.unitWiseQuantity.quantity}
                            </td>
                            <td className="tm_width_2 tm_text_right">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "INR",
                              }).format(item?.totalPrice)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tm_invoice_footer tm_border_top tm_mb15 tm_m0_md">
                  <div className="tm_left_footer">
                    <p className="tm_mb2">
                      <b className="tm_primary_color">Payment info:</b>
                    </p>
                    <p className="tm_m0">
                      {order.payment.paymentMethod} -{" "}
                      {order.payment.paymentStatus} <br />
                      Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "INR",
                      }).format(order.payment.paidAmount)}
                    </p>
                  </div>
                  <div className="tm_right_footer">
                    <table className="tm_mb15">
                      <tbody>
                        <tr className="tm_gray_bg">
                          <td className="tm_width_3 tm_primary_color tm_bold">
                            Subtoal
                          </td>
                          <td className="tm_width_3 tm_primary_color tm_bold tm_text_right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "INR",
                            }).format(order?.netAmount)}
                          </td>
                        </tr>
                        {order.productDiscount > 0 && (
                          <tr className="tm_gray_bg">
                            <td className="tm_width_3 tm_primary_color">
                              Product-Discount
                            </td>
                            <td className="tm_width_3 tm_primary_color tm_text_right">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "INR",
                              }).format(order?.productDiscount)}
                            </td>
                          </tr>
                        )}
                        {order.flatDiscount > 0 && (
                          <tr className="tm_gray_bg">
                            <td className="tm_width_3 tm_primary_color">
                              Flat-Discount
                            </td>
                            <td className="tm_width_3 tm_primary_color tm_text_right">
                              -
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "INR",
                              }).format(order?.flatDiscount)}
                            </td>
                          </tr>
                        )}
                        {order?.totalTaxAmountList?.map((item) => (
                          <tr className="tm_gray_bg">
                            <td
                              className="tm_width_3 tm_primary_color"
                              key={item.type}
                            >
                              {item.type}
                            </td>
                            <td className="tm_width_3 tm_primary_color tm_text_right">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "INR",
                              }).format(item?.amount)}
                            </td>
                          </tr>
                        ))}
                        <tr className="tm_accent_bg">
                          <td className="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_white_color">
                            Grand Total
                          </td>
                          <td className="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_white_color tm_text_right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "INR",
                            }).format(order?.totalAmount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tm_note tm_text_center tm_font_style_normal">
                <hr className="tm_mb15" />
                <p className="tm_m0">Invoice Created by Modulus Sell</p>
              </div>
            </div>
          </div>
          <div className="tm_invoice_btns tm_hide_print">
            <button className="tm_invoice_btn tm_color1" onClick={handlePrint}>
              <span className="tm_btn_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ionicon"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
                    fill="none"
                    stroke="currentColor"
                    stroke-linejoin="round"
                    stroke-width="32"
                  />
                  <rect
                    x="128"
                    y="240"
                    width="256"
                    height="208"
                    rx="24.32"
                    ry="24.32"
                    fill="none"
                    stroke="currentColor"
                    stroke-linejoin="round"
                    stroke-width="32"
                  />
                  <path
                    d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24"
                    fill="none"
                    stroke="currentColor"
                    stroke-linejoin="round"
                    stroke-width="32"
                  />
                  <circle cx="392" cy="184" r="24" fill="currentColor" />
                </svg>
              </span>
              <span className="tm_btn_text">Print</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
);
