import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GetRequest,
  PatchRequest,
  PostRequest,
} from '../../components/Request';
import { AsyncThunkConfig } from '../../interface';
import { RootState, store } from '../../store';

export type IStore = {
  _id: string;
  status: string;
  slug: string;
  userId: string;
  name: string;
  type: string[];
  location: Location;
  address: Address;
  email: string;
  phoneNumbers: PhoneNumber[];
  seller: boolean;
  buyer: boolean;
  profileImage: string;
  images: any[];
  tradeLicense: TradeLicense;
  verified: boolean;
  createdAt: Date;
  updatedAt: Date;
  typeList: string[];
  phoneNumberList: PhoneNumberList[];
  imageList: string[];
  profile: string;
  categoryList: string[];
  profileDetail: ProfileDetail;
};

export type Address = {
  district: string;
  state: string;
  country: string;
  postalCode: string;
};

export type Location = {
  type: string;
  coordinates: number[];
};

export type PhoneNumberList = {
  countryCode: string;
  nationalNumber: string;
};

export type PhoneNumber = {
  label: string;
  phoneNumber: string;
};

export type ProfileDetail = {
  _id: string;
  storeId: string;
  coverImage: CoverImage;
  aboutUs: AboutUs;
  banner: Banner;
  createdAt: Date;
  updatedAt: Date;
};

export type AboutUs = {
  text: string;
};

export type Banner = {
  imageList: CoverImage[];
};

export type CoverImage = {
  image: string;
};

export type TradeLicense = {
  number: string;
  document: string;
  verified: boolean;
};

export interface AddressObject {
  line1?: string;
  line2?: string;
  landmark?: string;
  locality?: string;
  city?: string;
  tehsil?: string;
  district: string;
  state?: string;
  country: string;
  postalCode: string;
}
export interface IStoreSlice {
  store: IStore | null;
}
// todo we need to store array of data of stores
const initialState: IStoreSlice = {
  store: null,
};

export const fetchStoreInfoAsyncThunk = createAsyncThunk<
  any, //IStore,
  any,
  AsyncThunkConfig
>('store/self', async (value: any, { rejectWithValue }) => {
  try {
    const respone = await GetRequest('store/my-store?buyer=true');
    return respone.data[0];
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error.data);
  }
});

export const CreateSellerStoreAsyncThunk = createAsyncThunk<
  any, //IStore,
  any, //IStore,
  AsyncThunkConfig
>('store/create', async (data: IStore, { rejectWithValue }) => {
  try {
    const response = await PostRequest('store', data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

function removeBlankAttributes(obj: any) {
  const filteredObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      // Skip empty arrays
      return;
    }
    filteredObj[key] = obj[key];
  });
  return filteredObj;
}

// export const UpdateSellerStoreAsyncThunk = createAsyncThunk<
//   any, //IStore,
//   any, //IStore,
//   AsyncThunkConfig
// >('store/update', async (value: any /*IStore*/, { rejectWithValue }) => {
//   try {
//     //?for removing null fileds
//     Object.keys(value).forEach(
//       (k: string) =>
//         value[k] === '' || (value[k] === undefined && delete value[k])
//     );
//     const data = removeBlankAttributes(value);
//     console.log(data);
//     const response = await PatchRequest('store', data);
//     console.log(response.data);
//     return response.data;
//   } catch (error: any) {
//     console.log(error);
//     return rejectWithValue(error.response.data);
//   }
// });

export const StoreSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    addStore: (state, action) => {
      state = action.payload;
    },
    removeStore: (state) => {
      state = initialState;
    },
  },
  // extraReducers: builder => {
  //   builder
  //     .addCase(CreateSellerStoreAsyncThunk.pending, (state, action) => {
  //       console.log(action.payload);
  //     })
  //     .addCase(CreateSellerStoreAsyncThunk.fulfilled, (state, action) => {
  //       console.log(action.payload);
  //     })
  //     .addCase(CreateSellerStoreAsyncThunk.rejected, (state, action) => {
  //       console.log('rejected', action.payload);
  //     })
  //     .addCase(UpdateSellerStoreAsyncThunk.pending, (state, action) => {
  //       console.log(action.payload);
  //     })
  //     .addCase(UpdateSellerStoreAsyncThunk.fulfilled, (state, action) => {
  //       console.log(action.payload);
  //     })
  //     .addCase(fetchStoreInfoAsyncThunk.pending, (state: IStore) => {
  //       // todo add some pending loader or
  //     })
  //     .addCase(
  //       fetchStoreInfoAsyncThunk.fulfilled,
  //       (state, {payload}: PayloadAction<IStore>) => {
  //         state.store = payload;
  //       },
  //     )
  //     .addCase(fetchStoreInfoAsyncThunk.rejected, (state: IStore, action) => {
  //       // todo add some pending loader or error
  //     });
  // },
});

// Action creators are generated for each case reducer function
export const { addStore, removeStore } = StoreSlice.actions;

export default StoreSlice.reducer;

// export const phoneNumberListSelecter = (state: RootState) =>state.store.store?.phoneNumberList;
// export const emailSelecter = (state: RootState) => state.store.store?.email;
// export const storeSelect = (state: RootState) => state.store.store;
// export const tradeLicenseSelect = (state: RootState) => state.store?.store?.tradeLicense;
