import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GetRequest } from "../../components/Request";
import { IOrder } from "../../interface/axios.types";
import { baseUrl } from "../../constant";
import { useReactToPrint } from "react-to-print";
import Loader from "../../layouts/Loader/Loader";
import { ComponentToPrint } from "./Invoice";

type Props = {};

export const OrderDetailPage = (props: Props) => {
  const [order, setOrder] = useState<IOrder | null>(null);
  const [loading, setLoading] = useState<boolean>();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const params = useParams();
  async function fetchOrderDetail() {
    try {
      setLoading(() => true);
      const response = await GetRequest(
        "order/" + params.orderId + "?seller-product-detail=true"
      );
      console.log(response.data.data);
      if (response.data.data) {
        setOrder(()=>response.data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchOrderDetail();
  }, []);

  if (loading) {
    return (
      <div className="pt-28 px-2 bg-white flex flex-1 justify-center items-center h-full">
        <Loader/>
      </div>
    );
  }
  if (order) {
    return (
      <div className="pt-28 px-2 bg-white">
        <>
        <ComponentToPrint order={order} ref={componentRef} handlePrint={handlePrint}/>
        </>
      </div>
    );
  } else {
    return (
      <div>
        <div className="flex flex-col items-center justify-center h-screen">
          <h1 className="text-3xl font-bold text-gray-900">No Orders Found</h1>
        </div>
      </div>
    );
  }
};
