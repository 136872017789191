import { MdEmail } from 'react-icons/md';
import './style/about.scss';
import { BsFillTelephoneFill } from 'react-icons/bs';

export default function About({ storeInfo }: any) {
  return (
    <div className="about-seller-section-cnt">
      <div className="gst-n-tag-cnt">
        <h1 className="tag">About Us</h1>
        <p className="gst">GST {storeInfo?.GST?.number && '-.-'}</p>
      </div>

      <p className="about-description">
        {storeInfo.profileDetail && storeInfo.profileDetail.aboutUs.text}
      </p>
      <div className="category-n-type-cnt">
        <div className="category-cnt">
          <p className="category">Store Category - </p>

          <p className="data">{storeInfo?.categoryList?.join(' , ')}</p>
        </div>
        <div className="type-cnt">
          <p className="type">Store Type - </p>
          <p className="data">{storeInfo?.typeList?.join(' , ')}</p>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex' }}>
          <p className="sub-heading">
            Contact us <BsFillTelephoneFill className="icon" /> -
          </p>
          <p className="data">
            {storeInfo?.phoneNumberList?.map((number: any) => {
              return `(${number.label})  ${number?.nationalNumber}`;
            })}
          </p>
        </div>
      </div>

      <h3 className="map-tag">See Store and Distributing Areas on map</h3>
      <iframe
        src={`https://maps.google.com/maps?q=${storeInfo.location.coordinates[1]}, ${storeInfo.location.coordinates[0]}&z=15&output=embed`}
        frameBorder="0"
        style={{
          border: 0,
          width: '100%',
          height: '400px',
          borderRadius: '10px',
        }}
      ></iframe>
    </div>
  );
}
