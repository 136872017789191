import { useEffect, useState } from 'react';
import { GetRequest } from '../../components/Request';
import './privacyPolicy.scss';

export default function PrivacyPolicy() {
  const [document, setDcoument] = useState<any>('');

  const getPrivacyPolicy = async () => {
    await GetRequest('/preference/privacy-policy')
      .then((res) => {
        setDcoument(res.data.data.value.description);
      })
      .catch((err) => {
        console.log(err, 'error in privacy policy');
      });
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <div
      className="privacy-policy-content"
      dangerouslySetInnerHTML={{ __html: document }}
    />
  );
}
