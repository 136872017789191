import React from 'react';
import './support.scss';

const Support = () => {
  return (
    <div className="support ">
      <section className="contact">
        <div className="contact-info">
          <p>Email: support@modulusbuy.com</p>
          <p>WhatsApp: +91 7356001171</p>
          <p>Address: Choori Kasaragod, Kerala 671124 Kerala ,India</p>
        </div>
      </section>

      <section className="content">
        <p>Explore our services tailored for your needs!</p>
      </section>
    </div>
  );
};

export default Support;
