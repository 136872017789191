import React, { useState, useEffect } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import './Indicator.scss';

const Indicator = ({
  message,
  type,
  errorId,
}: {
  message: String;
  type: String;
  errorId: String;
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let timer: any;

    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible]);

  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [errorId]);

  return (
    <div
      className={`success-indicator ${isVisible ? 'visible' : ''}`}
      style={{
        background: type == 'error' ? 'red' : 'green',
      }}
    >
      <div className="icon">
        {type === 'error' ? <RiErrorWarningLine /> : <AiOutlineCheckCircle />}
      </div>
      <div className="message">{message}</div>
    </div>
  );
};

export default Indicator;
